import React, { useState, useEffect } from "react";
import "../index.scss";
import { useSelector } from "react-redux";
import { Avatar, Divider, message, Select, TreeSelect } from "antd";
import { searchIdentity, sortUserFunc, stringAvatar } from "utils/commonUtils";
import useDebounce from "hooks/useDebounce";
import UserInfo from "common/UserInfo";
import UserInfoTooltip from "common/UserInfo/UserInfoTooltip";

const { SHOW_PARENT } = TreeSelect;

const UserTree = (props) => {
  const {
    filterDataPass,
    userNodes,
    isReload,
    singleSelect,
    selectedUserIds,
    singleSelectReload,
    userSelectWidth,
  } = props || {};

  const [searchString, setSearchString] = useState("");
  const [showedMoreLetterMessage, setShowedMoreLetterMessage] = useState(false);

  const { user_list_failed, user_list_processing, user_map_data } = useSelector(
    (state) => state.users
  );

  const [allSelect, setAllSelect] = useState(false);

  const debouncedSearchString = useDebounce(searchString, 1000);

  const filteredUser = userNodes.filter((userNode) => {
    const user = user_map_data[userNode.value];
    return searchIdentity(user.identity, searchString);
  });

  const handleClear = () => {
    setAllSelect(false);
    filterDataPass([]);
  };

  const handleaAllSelect = () => {
    if (userNodes.length <= 500) {
      setAllSelect(true);
      filterDataPass([]);
    }
  };

  useEffect(() => {
    if (filteredUser.length > 500 && searchString && !showedMoreLetterMessage) {
      message.warning("Type more letters to see the results");
      setShowedMoreLetterMessage(true);
      setTimeout(() => {
        setShowedMoreLetterMessage(false);
      }, 10000);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (isReload) {
      setAllSelect(false);
    }
  }, [isReload]);

  useEffect(() => {
    if (user_list_failed === true) {
      setAllSelect(false);
      filterDataPass([]);
    }
  }, [user_list_failed]);

  useEffect(() => {
    if (selectedUserIds.length >= 500) {
      message.warning("You can select maximum 500 users at a time");
    }
  }, [selectedUserIds]);

  const onChange = (newValue) => {
    if (newValue !== undefined) {
      if (singleSelect || newValue.length <= 500) {
        filterDataPass(singleSelect ? [newValue] : newValue);
      } else {
        message.warning("You can select maximum 500 users at a time");
      }
    }
    setAllSelect(false);
  };

  const dropdownRender = (menus) => (
    <div>
      {!singleSelect && (
        <>
          <div className="d_filter">
            {userNodes.length <= 500 ? (
              <div className="d_hover" onClick={handleaAllSelect}>
                {" "}
                Select all
              </div>
            ) : (
              <div />
            )}
            <div className="d_hover" onClick={handleClear}>
              Clear
            </div>
          </div>

          <Divider
            style={{
              margin: "0px",
              marginBottom: "5px",
            }}
          />
        </>
      )}
      {menus}
    </div>
  );

  const sortUsers = (userA, userB) => {
    return selectedUserIds.includes(userA.value) &&
      selectedUserIds.includes(userB.value)
      ? 0
      : selectedUserIds.includes(userA.value)
      ? -1
      : selectedUserIds.includes(userB.value)
      ? 1
      : sortUserFunc(
          user_map_data[userA.value]?.identity || {},
          user_map_data[userB.value]?.identity || {}
        );
  };

  return (
    // <TreeSelect
    //   dropdownMatchSelectWidth={false}
    //   autoClearSearchValue={false}
    //   loading={user_list_processing}
    //   treeData={
    //     userNodes.length <= 500
    //       ? userNodes.sort(sortUsers)
    //       : filteredUser.length <= 500
    //       ? filteredUser.sort(sortUsers)
    //       : !searchString
    //       ? userNodes
    //           .filter((userNode) => selectedUserIds.includes(userNode.value))
    //           .sort(sortUsers)
    //       : []
    //   }
    //   value={
    //     allSelect
    //       ? userNodes.map((userNode) => userNode.value)
    //       : selectedUserIds
    //   }
    //   onChange={onChange}
    //   allowClear={singleSelectReload && singleSelect}
    //   treeCheckable={!singleSelect}
    //   showCheckedStrategy={SHOW_PARENT}
    //   placeholder={
    //     userNodes.length <= 500
    //       ? singleSelectReload
    //         ? "Select Users"
    //         : singleSelect
    //         ? ""
    //         : "All Users"
    //       : "Type to search users..."
    //   }
    //   showSearch={true}
    //   showArrow={true}
    //   maxTagCount="responsive"
    //   onSearch={(value) => {
    //     setSearchString(value);
    //   }}
    //   style={{
    //     minWidth: userSelectWidth,
    //   }}
    //   treeTitleRender={(node) => {
    //     return <UserInfo id={node.id} hideUserDetailLink size="large" />;
    //   }}
    //   onClear={handleClear}
    //   dropdownRender={dropdownRender}
    //   filterTreeNode={(input, option) => {
    //     const user = user_map_data[option.value];
    //     return searchIdentity(user.identity, input);
    //   }}
    // />
    <Select
      dropdownMatchSelectWidth={false}
      className={`multi-user-select-filter`}
      mode={singleSelect ? false : "multiple"}
      allowClear={(singleSelectReload && singleSelect) || !singleSelect}
      showSearch
      autoClearSearchValue
      // onClear={handleClear}
      onSearch={(value) => {
        setSearchString(value);
      }}
      style={{ minWidth: userSelectWidth }}
      loading={user_list_processing}
      placeholder={
        userNodes.length <= 500
          ? singleSelectReload
            ? "Select Users"
            : singleSelect
            ? ""
            : "All Users"
          : "Type to search users..."
      }
      value={
        singleSelect
          ? selectedUserIds.length
            ? selectedUserIds[0]
            : null
          : allSelect
          ? userNodes.map((userNode) => userNode.value)
          : selectedUserIds
      }
      onChange={onChange}
      maxTagCount="responsive"
      maxTagPlaceholder={(omittedValues) => {
        if (user_list_processing) return null;
        return (
          <Avatar.Group
            maxCount={9}
            size="small"
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            {omittedValues.map((omittedValue) => (
              <UserInfoTooltip
                key={omittedValue.value}
                id={omittedValue.value}
                open
                hideUserDetailLinkWithRole
              >
                <Avatar
                  className="avatar"
                  {...stringAvatar(
                    user_map_data[omittedValue.value]?.identity?.first_name,
                    user_map_data[omittedValue.value]?.identity?.last_name
                  )}
                />
              </UserInfoTooltip>
            ))}
          </Avatar.Group>
        );
      }}
      filterOption={(input, option) => {
        const user = user_map_data[option.key];
        return searchIdentity(user.identity, input);
      }}
      optionLabelProp="label"
      dropdownRender={dropdownRender}
    >
      {(userNodes.length <= 500
        ? userNodes.sort(sortUsers)
        : filteredUser.length <= 500
        ? filteredUser.sort(sortUsers)
        : !searchString
        ? userNodes
            .filter((userNode) => selectedUserIds.includes(userNode.value))
            .sort(sortUsers)
        : []
      ).map((userNode) => (
        <Select.Option
          key={userNode.value}
          value={userNode.value}
          label={
            <UserInfo
              size="small"
              id={userNode.value}
              me
              nameClassName={singleSelect ? "" : "w-16"}
            />
          }
        >
          <UserInfo
            id={userNode.value}
            size="large"
            hideUserDetailLink
            hideStealthEmail
          />
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserTree;
