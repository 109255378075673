import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchIntegrations = (payload) => {
  return {
    type: actionTypes.FETCH_INTEGRATIONS,
    payload,
  };
};

export const fetchIntegrationsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_INTEGRATIONS_SUCCESS,
    payload,
  };
};

export const fetchIntegrationsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_INTEGRATIONS_FAILED,
    payload,
  };
};

export const createIntegration = (payload) => {
  return {
    type: actionTypes.CREATE_INTEGRATION,
    payload,
  };
};

export const createIntegrationSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_INTEGRATION_SUCCESS,
    payload,
  };
};

export const createIntegrationFailed = (payload) => {
  return {
    type: actionTypes.CREATE_INTEGRATION_FAILED,
    payload,
  };
};

export const deleteIntegration = (payload) => {
  return {
    type: actionTypes.DELETE_INTEGRATION,
    payload,
  };
};

export const deleteIntegrationSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_INTEGRATION_SUCCESS,
    payload,
  };
};

export const deleteIntegrationFailed = (payload) => {
  return {
    type: actionTypes.DELETE_INTEGRATION_FAILED,
    payload,
  };
};

export const updateIntegration = (payload) => {
  return {
    type: actionTypes.UPDATE_INTEGRATION,
    payload,
  };
};

export const updateIntegrationSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_INTEGRATION_SUCCESS,
    payload,
  };
};

export const updateIntegrationFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_INTEGRATION_FAILED,
    payload,
  };
};

// make for execute integration
export const executeIntegration = (payload) => {
  return {
    type: actionTypes.EXECUTE_INTEGRATION,
    payload,
  };
};

export const executeIntegrationSuccess = (payload) => {
  return {
    type: actionTypes.EXECUTE_INTEGRATION_SUCCESS,
    payload,
  };
};

export const executeIntegrationFailed = (payload) => {
  return {
    type: actionTypes.EXECUTE_INTEGRATION_FAILED,
    payload,
  };
};

export const setErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    payload,
  };
};
