import { put } from "redux-saga/effects";
import { IntegrationsAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchIntegrationsSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/admin/integrations/`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(IntegrationsAction.fetchIntegrationsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(IntegrationsAction.fetchIntegrationsFailed(errorMessage));
  }
}

export function* createIntegrationSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/admin/integrations/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(IntegrationsAction.createIntegrationSuccess(response.data));
    notification.success({
      message: `Saved Successfully`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(IntegrationsAction.createIntegrationFailed(errorMessage));
  }
}

export function* updateIntegrationSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/admin/integrations/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(IntegrationsAction.updateIntegrationSuccess(response.data));
    notification.success({
      message: `Saved Successfully`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(IntegrationsAction.updateIntegrationFailed(errorMessage));
  }
}

export function* deleteIntegrationSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/admin/integrations/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(IntegrationsAction.deleteIntegrationSuccess());
    notification.success({
      message: `Successfully Deleted Integration!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(IntegrationsAction.deleteIntegrationFailed(errorMessage));
  }
}

export function* executeIntegrationSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/admin/integrations/${action.payload.id}/execute`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(IntegrationsAction.executeIntegrationSuccess(response.data));
    notification.success({
      message: `Integration Executed Successfully`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(IntegrationsAction.executeIntegrationFailed(errorMessage));
  }
}
