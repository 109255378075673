import React, { useEffect } from "react";
import { FORBIDDEN, SIGNIN } from "route/constant";
import { redirectToPortal } from "utils/helper";
import sLogoai from "../../assets/images/logo_ai.png";

const ClearCookies = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search.substring(1));
    const code = params.get("code");
    const redirectUrl = params.get("redirect_url");

    if (redirectUrl) {
      window.open(redirectUrl, "_self");
    } else if (code === "403") {
      redirectToPortal(FORBIDDEN);
    } else {
      redirectToPortal(SIGNIN);
    }
  }, []);

  return (
    <div className="auth_layout">
      <div className="signup_blcok setpass_blcok">
        <div className="s_top">
          <div className=" cir_logo">
            <div className="logo_ai">
              <img src={sLogoai} />
            </div>
          </div>
          <center>
            <div>We360.ai</div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default ClearCookies;
