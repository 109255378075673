import dayjs from "dayjs";
export const hms = (value) => {
  if (!value || value < 0) {
    return "00h:00m:00s";
  }

  let hours = Math.abs(parseInt(value / 3600));
  if (hours >= 1000000) {
    hours = `${(hours / 1000000).toFixed(1)}M `;
  } else if (hours >= 1000) {
    hours = `${(hours / 1000).toFixed(1)}K `;
  } else {
    hours = hours.toString().padStart(2, "0");
  }

  const minutes = Math.abs(parseInt((value % 3600) / 60))
    .toString()
    .padStart(2, "0");
  const seconds = Math.abs(parseInt(value % 60))
    .toString()
    .padStart(2, "0");

  const sign = value < 0 ? "-" : "";

  return `${sign}${hours}h:${minutes}m:${seconds}s`;
};

export const hm = (value) => {
  if (!value || value < 0) {
    return "00h:00m";
  }

  let hours = Math.abs(parseInt(value / 3600));
  if (hours >= 1000000) {
    hours = `${(hours / 1000000).toFixed(1)}M `;
  } else if (hours >= 1000) {
    hours = `${(hours / 1000).toFixed(1)}K `;
  } else {
    hours = hours.toString().padStart(2, "0");
  }

  const minutes = Math.abs(parseInt((value % 3600) / 60))
    .toString()
    .padStart(2, "0");

  const sign = value < 0 ? "-" : "";

  return `${sign}${hours}h:${minutes}m`;
};

export const h = (value) => {
  if (!value || value < 0) {
    return "00h";
  }

  let hours = Math.abs(parseInt(value / 3600));
  if (hours >= 1000000) {
    hours = `${(hours / 1000000).toFixed(1)}M `;
  } else if (hours >= 1000) {
    hours = `${(hours / 1000).toFixed(1)}K `;
  } else {
    hours = hours.toString().padStart(2, "0");
  }

  const sign = value < 0 ? "-" : "";

  return `${sign}${hours}h`;
};

export const ms = (value) => {
  if (!value || value < 0) {
    return "00m:00s";
  }

  const minutes = Math.abs(parseInt((value % 3600) / 60))
    .toString()
    .padStart(2, "0");
  const seconds = Math.abs(parseInt(value % 60))
    .toString()
    .padStart(2, "0");

  return `${minutes}m:${seconds}s`;
};

export const getFormattedTime = (seconds) =>
  seconds >= 3600 ? hm(seconds) : ms(seconds);

export const onlyHoursNumber = (value) =>
  !value || value < 0 ? 0 : parseInt(value / 3600);

export function getHoursAndMinutesFromSecondsWithPadStart(seconds = 0) {
  try {
    const hours = Math.floor(seconds / 3600); // Get the number of whole hours
    const minutes = Math.floor((seconds % 3600) / 60); // Get the number of whole minutes remaining

    return `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}m `;
  } catch (ex) {
    return `00h 00m `;
  }
}

export const hmsOnlyNumber = (value) => {
  if (!value || value < 0) {
    return "00:00:00";
  }

  const hours = Math.abs(parseInt(value / 3600))
    .toString()
    .padStart(2, "0");

  const minutes = Math.abs(parseInt((value % 3600) / 60))
    .toString()
    .padStart(2, "0");
  const seconds = Math.abs(parseInt(value % 60))
    .toString()
    .padStart(2, "0");

  const sign = value < 0 ? "-" : "";

  return `${sign}${hours}:${minutes}:${seconds}`;
};

// Function to format the date range of a week
export const formatDateRange = (startDate) => {
  const start = dayjs(startDate).format("DD MMM");
  const end = dayjs(startDate).add(6, "day").format("DD MMM");
  return `${start}-${end}`;
};

export const getAmPmTime = (timeString) => {
  return dayjs(timeString, "HH:mm:ss").format("hh:mm A");
};

// Special usecase in timesheet, don't touch
export const getMillisFromTime = (time) =>
  new Date(`11/11/2011 ${time}`).getTime();
