import React, { useContext, useEffect, useState } from "react";

import { Avatar, Skeleton } from "antd";
import { getAvatarUrl } from "utils/transport";
import { AuthContext } from "contexts/authContext";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { openUserDetail } from "redux/store/user-detail/action";
import { stringToColor } from "utils/helper";
import { permissionsMap } from "constants/constant";
import dayjs from "dayjs";
import UserInfoTooltip from "./UserInfoTooltip";
import { UserListProcess } from "redux/store/users/action";
import { isStealth } from "utils/commonUtils";

interface Props {
  loading?: boolean;
  id: string;
  size?: "large" | "default" | "small";
  className?: string;
  nameClassName?: string;
  emailClassName?: string;
  tab?: number;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  hideUserDetailLink?: boolean;
  me?: boolean;
  noCache?: boolean;
  hideStealthEmail?: boolean;
}

const UserInfo = (props: Props) => {
  const {
    user_list_processing,
    user_map_data,
    user_search_map_data,
  }: {
    user_list_processing: boolean;
    user_map_data: { [key: string]: User };
    user_search_map_data: { [key: string]: User };
  } =
    // @ts-ignore
    useSelector((state) => state.users);

  const [lastCall, setLastCall] = useState(null);

  const {
    loading = user_list_processing,
    id,
    size = "default",
    className = "",
    nameClassName,
    emailClassName,
    tab,
    startDate,
    endDate,
    hideUserDetailLink = false,
    me = false,
    noCache = false,
    hideStealthEmail = false,
  } = props;

  const { coreApiUrl, checkRequiredPermissions, getDefaultHeader } =
    useContext(AuthContext);

  const hideUserDetailLinkWithRole =
    !checkRequiredPermissions([
      permissionsMap.TENANT_OWNER,
      permissionsMap.TENANT_MANAGER,
    ]) || hideUserDetailLink;
  const {
    medetails_data,
  }: {
    medetails_data: User;
  } =
    // @ts-ignore
    useSelector((state) => state.users);

  const dispatch = useDispatch();
  const stringAvatar = (firstName = "", lastName = "") => ({
    style: {
      background: stringToColor(`${firstName} ${lastName}`),
      fontSize: size === "large" ? "16px" : size === "small" ? "10px" : "14px",
    },
    children: `${firstName.length ? firstName[0].toUpperCase() : ""}${
      lastName.length ? lastName[0].toUpperCase() : ""
    }`,
  });

  const onUserNameClick = (e: React.MouseEvent) => {
    if (!hideUserDetailLinkWithRole) {
      e.stopPropagation();
      dispatch(openUserDetail({ identityId: id, tab, startDate, endDate }));
    }
  };

  useEffect(() => {
    if (
      id &&
      !user_list_processing &&
      (!lastCall || lastCall + 10000 < Date.now()) &&
      !user_map_data[id] &&
      !user_search_map_data[id]
    ) {
      dispatch(
        UserListProcess({
          headers: getDefaultHeader(),
          isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
          noCache: true,
        })
      );

      setLastCall(Date.now());
    }
  }, [id]);

  const { identity, employee_id } =
    user_map_data[id] || user_search_map_data[id] || {};

  const {
    first_name: firstName = "",
    last_name: lastName = "",
    email = "",
    avatar_uri: avatar = "",
  } = identity || {};

  const getName = () => (
    <div className="flex gap-2 items-center">
      <UserInfoTooltip
        id={id}
        open={size !== "large"}
        onUserDetail={onUserNameClick}
        hideUserDetailLinkWithRole={hideUserDetailLinkWithRole}
      >
        <div
          className={` ${nameClassName} user-name ellipsis-on  ${
            size === "large" ? "mb-[2px]" : ""
          } 
               ${size === "small" ? "text-xs" : ""}`}
          // onClick={onUserNameClick}
        >
          {medetails_data?.identity?.id === id && me
            ? "Me"
            : `${firstName || ""} ${lastName || ""}`}
        </div>
      </UserInfoTooltip>
      {employee_id && size === "large" && (
        <div
          className={`${
            // @ts-ignore
            size === "small"
              ? "text-[6px]"
              : size === "large"
              ? "text-[10px]"
              : "text-[8px]"
          } text-gray-500 bg-gray-100 px-1 rounded border border-solid border-gray-300`}
        >
          {employee_id}
        </div>
      )}
    </div>
  );

  return (
    <div className={`user-detail ${className}`}>
      <div>
        {loading ? (
          <Skeleton.Avatar size={size} className="avatar" active />
        ) : avatar ? (
          <Avatar
            className="avatar"
            size={size}
            src={
              avatar === "@we360"
                ? noCache || medetails_data.identity.id === id
                  ? `${getAvatarUrl(coreApiUrl, id)}`
                  : getAvatarUrl(coreApiUrl, id)
                : avatar
            }
          />
        ) : (
          <Avatar
            className="avatar"
            size={size}
            {...stringAvatar(firstName, lastName)}
          />
        )}
      </div>
      <div className="user-info">
        {loading ? (
          <>
            <div>
              <Skeleton.Button
                size="small"
                active
                className={`skeleton-name  ${size === "large" ? "mb-1" : ""}`}
              />
            </div>

            {size === "large" && (
              <div>
                <Skeleton.Button
                  size="small"
                  active
                  className="skeleton-email"
                />
              </div>
            )}
          </>
        ) : (
          <>
            {getName()}
            {size === "large" && (
              <div className={`${emailClassName} user-email`}>
                {isStealth(email) && hideStealthEmail ? "Stealth User" : email}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
