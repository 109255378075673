import * as actionTypes from "./actionTypes";

const initialState = {
  legacyMenu: localStorage.getItem("legacy_menu") === "true",
  bannerInfo: null,
  fetchTenantSettingsLoading: true,
  tenantSettingsData: {},
  tenantSettingsLoading: false,
  tenantSettingsSuccess: false,
  tenantSettingsErrorMessage: "",
  UserSessionData: [],
  UserSessionLoading: false,
  UserSessionSuccess: false,
  UserSessionFailed: false,
  UserSessionErrorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_LEGACY_MENU: {
      localStorage.setItem("legacy_menu", action.payload);
      return {
        ...state,
        legacyMenu: action.payload,
      };
    }

    case actionTypes.SET_BANNER_INFO:
      return {
        ...state,
        bannerInfo: action.payload,
      };

    case actionTypes.FETCH_TENANT_SETTINGS:
      return {
        ...state,
        fetchTenantSettingsLoading: true,
        tenantSettingsErrorMessage: "",
      };

    case actionTypes.FETCH_TENANT_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchTenantSettingsLoading: false,
        tenantSettingsData: action.payload,
      };

    case actionTypes.FETCH_TENANT_SETTINGS_FAILED:
      return {
        ...state,
        fetchTenantSettingsLoading: false,
        tenantSettingsErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_TENANT_SETTINGS:
      return {
        ...state,
        tenantSettingsLoading: true,
        tenantSettingsErrorMessage: "",
        tenantSettingsSuccess: false,
      };

    case actionTypes.UPDATE_TENANT_SETTINGS_SUCCESS:
      return {
        ...state,
        tenantSettingsLoading: false,
        tenantSettingsData: action.payload,
        tenantSettingsSuccess: true,
      };

    case actionTypes.UPDATE_TENANT_SETTINGS_FAILED:
      return {
        ...state,
        tenantSettingsLoading: false,
        tenantSettingsErrorMessage: action.payload,
      };

    case actionTypes.SET_TENANT_SETTINGS_ERROR_MESSAGE:
      return {
        ...state,
        tenantSettingsErrorMessage: action.payload,
      };
    case actionTypes.FETCH_USER_SESSION:
      return {
        ...state,
        UserSessionLoading: true,
        UserSessionErrorMessage: "",
      };

    case actionTypes.FETCH_USER_SESSION_SUCCESS:
      return {
        ...state,
        UserSessionLoading: false,
        UserSessionData: action.payload,
      };

    case actionTypes.FETCH_USER_SESSION_FAILED:
      return {
        ...state,
        UserSessionLoading: false,
        UserSessionErrorMessage: action.payload,
      };
    default:
      return state;
  }
};
