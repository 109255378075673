import * as actionTypes from "./actionTypes";

const initialState = {
  teamUserPin: false,
  datePin: false,
  teamUserPinDate: new Date(),
  datePinDate: new Date(),
  teamParentIds: [],
  teamIds: [],
  userIds: [],
  date: null,
  startDate: null,
  endDate: null,
  month: null,
  weekStartDate: null,
  weekEndDate: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_TEAM_USER_PIN:
      return {
        ...state,
        teamUserPin: action.payload,
        teamUserPinDate: new Date(),
      };

    case actionTypes.SET_DATE_PIN:
      return {
        ...state,
        datePin: action.payload,
        datePinDate: new Date(),
      };

    case actionTypes.SET_TEAM_IDS:
      return {
        ...state,
        teamIds: action.payload,
      };

    case actionTypes.SET_TEAM_PARENT_IDS:
      return {
        ...state,
        teamParentIds: action.payload,
      };

    case actionTypes.SET_USER_IDS:
      return {
        ...state,
        userIds: action.payload,
      };

    case actionTypes.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case actionTypes.SET_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };

    case actionTypes.SET_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };

    case actionTypes.SET_MONTH:
      return {
        ...state,
        month: action.payload,
      };

    case actionTypes.SET_WEEK_START_DATE:
      return {
        ...state,
        weekStartDate: action.payload,
      };

    case actionTypes.SET_WEEK_END_DATE:
      return {
        ...state,
        weekEndDate: action.payload,
      };

    default:
      return state;
  }
};
