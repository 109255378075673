/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import UserMonthlyPrductivityTrend from "./UserMonthlyPrductivityTrend";
import { Divider } from "antd";
import dayjs from "dayjs";
import { getHoursAndMinutesFromSecondsWithPadStart } from "utils/timeConverter";

const UserProducitivtyTrend = (props) => {
  const { teamWiseProductivityTrendData, employeeListData } = props;
  const productivityPer =
    employeeListData.length > 0 && employeeListData[0].activityper;
  const [mostProductiveDate, setMostProductiveDate] = useState("");
  const [mostUnProductiveDate, setMostUnProductiveDate] = useState("");
  console.log("employeeListData", employeeListData);
  useEffect(() => {
    if (Object.keys(teamWiseProductivityTrendData).length > 0) {
      const { date } = teamWiseProductivityTrendData;
      const maxProductivtyTimeIndex =
        teamWiseProductivityTrendData?.productiveTime.reduce(
          (maxIndex, currentValue, currentIndex, array) => {
            return currentValue > array[maxIndex] ? currentIndex : maxIndex;
          },
          0
        );

      // Get the corresponding date
      const mostProductiveDate = date[maxProductivtyTimeIndex];
      setMostProductiveDate(mostProductiveDate);
      const maxUnProductivtyTimeIndex =
        teamWiseProductivityTrendData?.unproductiveTime.reduce(
          (maxIndex, currentValue, currentIndex, array) => {
            return currentValue > array[maxIndex] ? currentIndex : maxIndex;
          },
          0
        );

      // Get the corresponding date
      const mostUnProductiveDate = date[maxUnProductivtyTimeIndex];
      setMostUnProductiveDate(mostUnProductiveDate);
    }
  }, [teamWiseProductivityTrendData]);
  return (
    <>
      <div className="bg-white border  rounded-[5px] mt-4 p-4 overflow-hidden rounded-lg border-[1px] border-solid border-gray-200 bg-white shadow-sm">
        <div className="flex items-center justify-between ">
          <h3 className="font-semibold text-[16px] text-slate-500 mt-0">
            Productivity
          </h3>
        </div>
        <div className="w-full bg-neutral-200">
          <div
            className={`${
              productivityPer >= 0 && productivityPer <= 50
                ? "bg-[#14B8A6]"
                : productivityPer > 50 && productivityPer <= 75
                ? "bg-[#14B8A6]"
                : productivityPer > 75
                ? "bg-[#14B8A6]"
                : "bg-white-100"
            } p-0.5 h-[4px] text-center text-xs font-medium leading-none text-slate-100`}
            style={{ width: productivityPer + `%` }}
          ></div>
        </div>
        <div className="flex items-center justify-between pt-4 pb-4">
          <div className="font-extrabold text-[20px] text-[#000]">
            {productivityPer}%
          </div>
        </div>{" "}
        <UserMonthlyPrductivityTrend
          teamWiseProductivityTrendData={teamWiseProductivityTrendData}
          // title={"Productivity Trend"}
          tooltip={""}
          hideTooltip={true}
        />{" "}
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Total Productive Time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {employeeListData.length > 0 &&
              getHoursAndMinutesFromSecondsWithPadStart(
                employeeListData[0].totalproductivetime
              )}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Total Neutral Time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {employeeListData.length > 0 &&
              getHoursAndMinutesFromSecondsWithPadStart(
                employeeListData[0].totalneutraltime
              )}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Total Unproductive Time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {employeeListData.length > 0 &&
              getHoursAndMinutesFromSecondsWithPadStart(
                employeeListData[0].totalunproductivetime
              )}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Average Productive time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {" "}
            {employeeListData.length > 0 &&
            employeeListData[0].totalproductivetime > 0
              ? getHoursAndMinutesFromSecondsWithPadStart(
                  employeeListData[0].totalproductivetime /
                    employeeListData[0].attendance
                )
              : "0h 0m"}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Average Neutral time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {" "}
            {employeeListData.length > 0 &&
            employeeListData[0].totalneutraltime > 0
              ? getHoursAndMinutesFromSecondsWithPadStart(
                  employeeListData[0].totalneutraltime /
                    employeeListData[0].attendance
                )
              : "0h 0m"}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Average Unproductive time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {" "}
            {employeeListData.length > 0 &&
            employeeListData[0].totalunproductivetime > 0
              ? getHoursAndMinutesFromSecondsWithPadStart(
                  employeeListData[0].totalunproductivetime /
                    employeeListData[0].attendance
                )
              : "0h 0m"}
          </div>
        </div>
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Most productive day</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {mostProductiveDate &&
              dayjs(mostProductiveDate).format("DD MMMM YYYY, dddd")}
          </div>
        </div>
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Most unproductive day</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {mostUnProductiveDate &&
              dayjs(mostUnProductiveDate).format("DD MMMM YYYY, dddd")}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserProducitivtyTrend;
