import * as actionTypes from "./actionTypes";

export const fetchTimeEntry = (payload) => {
  return {
    type: actionTypes.FETCH_TIME_ENTRY,
    payload,
  };
};

export const fetchTimeEntrySuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TIME_ENTRY_SUCCESS,
    payload,
  };
};

export const fetchTimeEntryFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TIME_ENTRY_FAILED,
    payload,
  };
};

export const createTimeEntry = (payload) => {
  return {
    type: actionTypes.CREATE_TIME_ENTRY,
    payload,
  };
};

export const createTimeEntrySuccess = (payload) => {
  return {
    type: actionTypes.CREATE_TIME_ENTRY_SUCCESS,
    payload,
  };
};

export const createTimeEntryFailed = (payload) => {
  return {
    type: actionTypes.CREATE_TIME_ENTRY_FAILED,
    payload,
  };
};

export const updateTimeEntry = (payload) => {
  return {
    type: actionTypes.UPDATE_TIME_ENTRY,
    payload,
  };
};

export const updateTimeEntrySuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_TIME_ENTRY_SUCCESS,
    payload,
  };
};

export const updateTimeEntryFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_TIME_ENTRY_FAILED,
    payload,
  };
};

export const bulkSubmitTimeEntry = (payload) => {
  return {
    type: actionTypes.BULK_SUBMIT_TIME_ENTRY,
    payload,
  };
};

export const bulkSubmitTimeEntrySuccess = (payload) => {
  return {
    type: actionTypes.BULK_SUBMIT_TIME_ENTRY_SUCCESS,
    payload,
  };
};

export const bulkSubmitTimeEntryFailed = (payload) => {
  return {
    type: actionTypes.BULK_SUBMIT_TIME_ENTRY_FAILED,
    payload,
  };
};

export const setTimeEntryErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_TIME_ENTRY_ERROR_MESSAGE,
    payload,
  };
};
export const deleteTimeEntry = (payload) => {
  return {
    type: actionTypes.DELETE_TIME_ENTRY,
    payload,
  };
};

export const deleteTimeEntrySuccess = (payload) => {
  return {
    type: actionTypes.DELETE_TIME_ENTRY_SUCCESS,
    payload,
  };
};

export const deleteTimeEntryFailed = (payload) => {
  return {
    type: actionTypes.DELETE_TIME_ENTRY_FAILED,
    payload,
  };
};

export const fetchTimesheet = (payload) => {
  return {
    type: actionTypes.FETCH_TIMESHEET,
    payload,
  };
};

export const fetchTimesheetSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TIMESHEET_SUCCESS,
    payload,
  };
};

export const fetchTimesheetFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TIMESHEET_FAILED,
    payload,
  };
};

export const reviewTimesheet = (payload) => {
  return {
    type: actionTypes.REVIEW_TIMESHEET,
    payload,
  };
};

export const reviewTimesheetSuccess = (payload) => {
  return {
    type: actionTypes.REVIEW_TIMESHEET_SUCCESS,
    payload,
  };
};

export const reviewTimesheetFailed = (payload) => {
  return {
    type: actionTypes.REVIEW_TIMESHEET_FAILED,
    payload,
  };
};

export const setTimesheetErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_TIMESHEET_ERROR_MESSAGE,
    payload,
  };
};

//export const FETCH_ALL_USER_TIMESHEETS = "FETCH_ALL_USER_TIMESHEETS";
// export const FETCH_ALL_USER_TIMESHEETS_SUCCESS =
// "FETCH_ALL_USER_TIMESHEETS_SUCCESS";
// export const FETCH_ALL_USER_TIMESHEETS_FAILED =
// "FETCH_ALL_USER_TIMESHEETS_FAILED";

// make action for these
export const fetchAllUserTimesheets = (payload) => {
  return {
    type: actionTypes.FETCH_ALL_USER_TIMESHEETS,
    payload,
  };
};

export const fetchAllUserTimesheetsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_ALL_USER_TIMESHEETS_SUCCESS,
    payload,
  };
};

export const fetchAllUserTimesheetsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_ALL_USER_TIMESHEETS_FAILED,
    payload,
  };
};
