import * as actionTypes from "./actionTypes";

const initialState = {
  fetchIntegrationsLoading: true,
  integrationsData: {},
  integrationLoading: false,
  integrationSuccess: false,
  deleteIntegrationSuccess: false,
  deleteIntegrationLoading: false,
  executeIntegrationLoading: false,
  errorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_INTEGRATIONS:
      return {
        ...state,
        fetchIntegrationsLoading: true,
        errorMessage: "",
      };

    case actionTypes.FETCH_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        fetchIntegrationsLoading: false,
        integrationsData: action.payload.items.reduce(
          (acc, integration) => ({
            ...acc,
            [integration.integration_type]: integration,
          }),
          {}
        ),
      };

    case actionTypes.FETCH_INTEGRATIONS_FAILED:
      return {
        ...state,
        fetchIntegrationsLoading: false,
        integrationsData: {},
        errorMessage: action.payload,
      };

    case actionTypes.CREATE_INTEGRATION:
      return {
        ...state,
        integrationLoading: true,
        errorMessage: "",
        integrationSuccess: false,
      };

    case actionTypes.CREATE_INTEGRATION_SUCCESS:
      return {
        ...state,
        integrationLoading: false,
        integrationsData: {
          ...state.integrationsData,
          [action.payload.integration_type]: action.payload,
        },
        integrationSuccess: true,
      };

    case actionTypes.CREATE_INTEGRATION_FAILED:
      return {
        ...state,
        integrationLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.UPDATE_INTEGRATION:
      return {
        ...state,
        integrationLoading: true,
        errorMessage: "",
        integrationSuccess: false,
      };

    case actionTypes.UPDATE_INTEGRATION_SUCCESS:
      return {
        ...state,
        integrationLoading: false,
        integrationsData: {
          ...state.integrationsData,
          [action.payload.integration_type]: action.payload,
        },
        integrationSuccess: true,
      };

    case actionTypes.UPDATE_INTEGRATION_FAILED:
      return {
        ...state,
        integrationLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.DELETE_INTEGRATION:
      return {
        ...state,
        deleteIntegrationLoading: true,
        errorMessage: "",
        deleteIntegrationSuccess: false,
      };

    case actionTypes.DELETE_INTEGRATION_SUCCESS:
      return {
        ...state,
        deleteIntegrationLoading: false,
        deleteIntegrationSuccess: true,
      };

    case actionTypes.DELETE_INTEGRATION_FAILED:
      return {
        ...state,
        deleteIntegrationLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.EXECUTE_INTEGRATION:
      return {
        ...state,
        executeIntegrationLoading: true,
        errorMessage: "",
      };

    case actionTypes.EXECUTE_INTEGRATION_SUCCESS:
      return {
        ...state,
        executeIntegrationLoading: false,
      };

    case actionTypes.EXECUTE_INTEGRATION_FAILED:
      return {
        ...state,
        executeIntegrationLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
