/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Space, Avatar, Tooltip, message, Switch } from "antd";
// import { DownOutlined } from '@ant-design/icons';
import { CaretDownOutlined, CopyOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "./header.scss";
// import {Profileicon} from './../../components/icons/profile'
// import {Api} from './../../components/icons/api'
import { Pdownload } from "./../../components/icons/Pdownload";
import { Logout } from "./../../components/icons/logout";
import { Settings } from "./../../components/icons/settings";
// import {Team} from './../../components/icons/team'
import { ACCOUNT, BILLING, DOWNLOAD } from "route/constant";
import { AiOutlineContainer } from "react-icons/ai";
import { AuthContext } from "../../contexts/authContext";
import UserInfo from "common/UserInfo/index.tsx";
import { getAvatarUrl, getLogoUrl } from "utils/transport";
import { permissionsMap } from "constants/constant";
import { copyToClipboard, stringAvatar } from "utils/commonUtils";
import {
  fetchUserSession,
  setLegacyMenu,
} from "redux/store/tenant-settings/action";
// const DarkTheme = lazy(() => import("./../../theme/dark-color.scss"));
import { RxCounterClockwiseClock } from "react-icons/rx";
const Profile = (props) => {
  const { ProfileModal, sessionModal } = props;
  const {
    KeyCloaklogout,
    checkRequiredPermissions,
    coreApiUrl,
    getDefaultHeader,
  } = useContext(AuthContext);
  const [darkMode, setDarkMode] = useState();
  const dispatch = useDispatch();
  const { legacyMenu } = useSelector((state) => state.tenantSettings);
  const tenantdata = useSelector((state) => state.tenant);
  const users = useSelector((state) => state.users);

  const navigate = useNavigate();
  const handleAction = () => {
    navigate(DOWNLOAD);
  };

  useEffect(() => {
    if (darkMode === true) {
      setDarkMode(true);
      document.body.className += "darkmode";
      document.body.classList.remove("lightmode");
      // import("./../../theme/dark-color.scss");
    } else if (darkMode === false) {
      document.body.classList.add("lightmode");
    }
    return function cleanup() {
      document.body.classList.remove("lightmode");
      document.body.classList.remove("darkmode");
    };
  }, [darkMode]);

  const onClickProfile = () => {
    ProfileModal();
  };

  const onClickSession = () => {
    dispatch(fetchUserSession({ headers: getDefaultHeader() }));
    sessionModal();
  };

  const items = [
    {
      label: (
        <>
          <div className="profile_avtar" onClick={() => onClickProfile()}>
            <Link to="#0">
              <UserInfo
                id={users.medetails_data?.identity?.id}
                size="large"
                noCache
              />
            </Link>
          </div>{" "}
        </>
      ),
      key: "0",
    },

    {
      type: "divider",
    },
    {
      label: (
        <>
          {checkRequiredPermissions([permissionsMap.TENANT_OWNER]) && (
            <Tooltip title="Copy">
              <p
                className="flex gap-1 text-xs text-gray-500"
                onClick={(e) => {
                  copyToClipboard(tenantdata?.tenant_list_data?.id, false);
                  message.success("Customer ID copied to clipboard.");
                }}
              >
                {`Customer ID: ${tenantdata?.tenant_list_data?.id}`}
                <CopyOutlined className=" rounded hover:text-gray-900" />
              </p>
            </Tooltip>
          )}
        </>
      ),
      key: "1",
      disabled: !checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
    },
    {
      type: "divider",
    },
    {
      label: (
        <>
          <div className="account_avtar">
            <Link to={ACCOUNT}>
              <div className="a_block">
                {" "}
                {
                  <Avatar
                    className=""
                    {...stringAvatar(
                      tenantdata?.tenant_list_data?.name,
                      "",
                      "18px"
                    )}
                    src={`${getLogoUrl(coreApiUrl)}`}
                  />
                }
                <p className="text_profile">
                  {tenantdata?.tenant_list_data?.name}
                </p>
              </div>{" "}
              <Settings />
            </Link>{" "}
          </div>
        </>
      ),
      key: "2",
      disabled: !checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className="p_icon_text" onClick={() => onClickSession()}>
          <RxCounterClockwiseClock />
          Active Sessions
        </div>
      ),
      key: "3",
      disabled: !checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to={BILLING}>
          <div className="p_icon_text">
            <AiOutlineContainer />
            Billing
          </div>
        </Link>
      ),
      key: "4",
      disabled: !checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
    },
    {
      type: "divider",
    },
    // {
    //   label: (
    //     <>
    //       <div className="dark-mode">
    //         <div className="ion">
    //           <MdOutlineNightlight />
    //           Dark mode
    //         </div>
    //         <Switch size="small" onChange={HandleTheme} />
    //       </div>
    //     </>
    //   ),
    //   key: "2",
    // },
    // {
    //   type: "divider",
    // },
    {
      label: (
        <>
          <div className="p_icon_text" onClick={handleAction}>
            <Pdownload />
            Download Apps
          </div>
        </>
      ),
      key: "5",
    },
    {
      type: "divider",
    },
    {
      label: (
        <>
          <div
            className="p_icon_text"
            onClick={() => {
              KeyCloaklogout();
            }}
          >
            <Logout />
            Logout
          </div>
        </>
      ),
      key: "6",
    },
    {
      label: (
        <>
          <div className="flex items-center  gap-8 justify-between text-xs text-blue-600 font-semibold h-6">
            Switch to Legacy Mode
            <Switch
              size="small"
              onChange={(checked) => dispatch(setLegacyMenu(checked))}
              checked={legacyMenu}
            />
          </div>
        </>
      ),
      key: "7",
    },
  ];

  const ownerKey = ["4"];

  return (
    <Dropdown
      menu={{
        items: items.filter(
          (item) =>
            !ownerKey.includes(item.key) ||
            checkRequiredPermissions([permissionsMap.TENANT_OWNER])
        ),
      }}
      trigger={["click"]}
      className="drop_profile"
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {users.medetails_data?.identity?.avatar_uri ? (
            <Avatar
              src={
                users.medetails_data?.identity?.avatar_uri === "@we360"
                  ? `${getAvatarUrl(
                      coreApiUrl,
                      users.medetails_data?.identity?.id
                    )}`
                  : users.medetails_data?.identity?.avatar_uri
              }
            />
          ) : (
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              {...stringAvatar(
                users.medetails_data?.identity?.first_name,
                users.medetails_data?.identity?.last_name,
                "14px"
              )}
            />
          )}
          <CaretDownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

Profile.propTypes = {
  showBackground: PropTypes.bool,
};

export default Profile;
