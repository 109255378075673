import dayjs from "dayjs";
import _ from "lodash";

const GetParamers = (
  startDate = "",
  endDate = "",
  groupIds = [],
  userIds = [],
  page = 1,
  limit = 0,
  datewise = false,
  summary = false,
  cat_name = "",
  app = "",
  url = "",
  report = false,
  type = "",
  attendance_type = "all",
  name = "",
  sort_by = "",
  desc = false,
  type_name = "",
  policy_wise = false,
  return_all = false
) => ({
  start_date: dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00.474505",
  end_date: dayjs(endDate).format("YYYY-MM-DD") + "T23:59:59.474505",
  group_id: _.size(groupIds) > 0 ? groupIds.join(",") : "",
  user_id: _.size(userIds) > 0 ? userIds.join(",") : "",
  page,
  limit,
  datewise,
  summary,
  cat_name,
  app,
  url,
  report,
  type,
  attendance_type,
  name,
  sort_by,
  desc,
  type_name,
  policy_wise,
  return_all,
});

export default GetParamers;
