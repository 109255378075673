export const FETCH_URLBlOCKING = "FETCH_URLBlOCKING";
export const FETCH_URLBlOCKING_SUCCESS = "FETCH_URLBlOCKING_SUCCESS";
export const FETCH_URLBlOCKING_FAILED = "FETCH_URLBlOCKING_FAILED";
export const FETCH_URL_POLICIES_ATTACHMENT = "FETCH_URL_POLICIES_ATTACHMENT";
export const FETCH_URL_POLICIES_ATTACHMENT_SUCCESS =
  "FETCH_URL_POLICIES_ATTACHMENT_SUCCESS";
export const FETCH_URL_POLICIES_ATTACHMENT_FAILED =
  "FETCH_URL_POLICIES_ATTACHMENT_FAILED";

export const CREATE_URLBlOCKING = "CREATE_URLBlOCKING";
export const CREATE_URLBlOCKING_SUCCESS = "CREATE_URLBlOCKING_SUCCESS";
export const CREATE_URLBlOCKING_FAILED = "CREATE_URLBlOCKING_FAILED";

export const CREATE_URL_POLICIES_ATTACHMENT = "CREATE_URL_POLICIES_ATTACHMENT";
export const CREATE_URL_POLICIES_ATTACHMENT_SUCCESS =
  "CREATE_URL_POLICIES_ATTACHMENT_SUCCESS";
export const CREATE_URL_POLICIES_ATTACHMENT_FAILED =
  "CREATE_URL_POLICIES_ATTACHMENT_FAILED";

export const DELETE_URLBlOCKING = "DELETE_URLBlOCKING";
export const DELETE_URLBlOCKING_SUCCESS = "DELETE_URLBlOCKING_SUCCESS";
export const DELETE_URLBlOCKING_FAILED = "DELETE_URLBlOCKING_FAILED";
export const DELETE_URL_POLICY_ATTACHMENT = "DELETE_URL_POLICY_ATTACHMENT";
export const DELETE_URL_POLICY_ATTACHMENT_SUCCESS =
  "DELETE_URL_POLICY_ATTACHMENT_SUCCESS";
export const DELETE_URL_POLICY_ATTACHMENT_FAILED =
  "DELETE_URL_POLICY_ATTACHMENT_FAILED";

export const UPDATE_URLBlOCKING = "UPDATE_URLBlOCKING";
export const UPDATE_URLBlOCKING_SUCCESS = "UPDATE_URLBlOCKING_SUCCESS";
export const UPDATE_URLBlOCKING_FAILED = "UPDATE_URLBlOCKING_FAILED";

export const UPDATE_URL_POLICY_ATTACHMENT = "UPDATE_URL_POLICY_ATTACHMENT";
export const UPDATE_URL_POLICY_ATTACHMENT_SUCCESS =
  "UPDATE_URL_POLICY_ATTACHMENT_SUCCESS";
export const UPDATE_URL_POLICY_ATTACHMENT_FAILED =
  "UPDATE_URL_POLICY_ATTACHMENT_FAILED";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
