export const FETCH_TIME_ENTRY = "FETCH_TIME_ENTRY";
export const FETCH_TIME_ENTRY_SUCCESS = "FETCH_TIME_ENTRY_SUCCESS";
export const FETCH_TIME_ENTRY_FAILED = "FETCH_TIME_ENTRY_FAILED";

export const CREATE_TIME_ENTRY = "CREATE_TIME_ENTRY";
export const CREATE_TIME_ENTRY_SUCCESS = "CREATE_TIME_ENTRY_SUCCESS";
export const CREATE_TIME_ENTRY_FAILED = "CREATE_TIME_ENTRY_FAILED";

export const UPDATE_TIME_ENTRY = "UPDATE_TIME_ENTRY";
export const UPDATE_TIME_ENTRY_SUCCESS = "UPDATE_TIME_ENTRY_SUCCESS";
export const UPDATE_TIME_ENTRY_FAILED = "UPDATE_TIME_ENTRY_FAILED";

export const BULK_SUBMIT_TIME_ENTRY = "BULK_SUBMIT_TIME_ENTRY";
export const BULK_SUBMIT_TIME_ENTRY_SUCCESS = "BULK_SUBMIT_TIME_ENTRY_SUCCESS";
export const BULK_SUBMIT_TIME_ENTRY_FAILED = "BULK_SUBMIT_TIME_ENTRY_FAILED";

export const DELETE_TIME_ENTRY = "DELETE_TIME_ENTRY";
export const DELETE_TIME_ENTRY_SUCCESS = "DELETE_TIME_ENTRY_SUCCESS";
export const DELETE_TIME_ENTRY_FAILED = "DELETE_TIME_ENTRY_FAILED";

export const SET_TIME_ENTRY_ERROR_MESSAGE = "SET_TIME_ENTRY_ERROR_MESSAGE";

export const FETCH_TIMESHEET = "FETCH_TIMESHEET";
export const FETCH_TIMESHEET_SUCCESS = "FETCH_TIMESHEET_SUCCESS";
export const FETCH_TIMESHEET_FAILED = "FETCH_TIMESHEET_FAILED";

export const REVIEW_TIMESHEET = "REVIEW_TIMESHEET";
export const REVIEW_TIMESHEET_SUCCESS = "REVIEW_TIMESHEET_SUCCESS";
export const REVIEW_TIMESHEET_FAILED = "REVIEW_TIMESHEET_FAILED";

export const SET_TIMESHEET_ERROR_MESSAGE = "SET_TIMESHEET_ERROR_MESSAGE";

export const FETCH_ALL_USER_TIMESHEETS = "FETCH_ALL_USER_TIMESHEETS";
export const FETCH_ALL_USER_TIMESHEETS_SUCCESS =
  "FETCH_ALL_USER_TIMESHEETS_SUCCESS";
export const FETCH_ALL_USER_TIMESHEETS_FAILED =
  "FETCH_ALL_USER_TIMESHEETS_FAILED";
