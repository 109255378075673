import { put } from "redux-saga/effects";
import { TimesheetAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchTimeEntrySaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/time_entries/search/?${action.payload.query}`,
    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.fetchTimeEntrySuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.fetchTimeEntryFailed(errorMessage));
  }
}

export function* createTimeEntrySaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/time_entries/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.createTimeEntrySuccess(response.data));
    // notification.success({
    //   message: `Successfully Added Time Entry!`,
    // });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.createTimeEntryFailed(errorMessage));
  }
}

export function* updateTimeEntrySaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/me/time_entries/${action.payload.id}`,

    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.updateTimeEntrySuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.updateTimeEntryFailed(errorMessage));
  }
}

export function* deleteTimeEntrySaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/me/time_entries/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(TimesheetAction.deleteTimeEntrySuccess(action.payload));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.deleteTimeEntryFailed(errorMessage));
  }
}

export function* bulkSubmitTimeEntrySaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/time_entries/bulk_submit/`,
    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    yield Client.request(config);
    yield put(TimesheetAction.bulkSubmitTimeEntrySuccess(action.payload.body));
    notification.success({
      message: `Your timesheet was submitted successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.bulkSubmitTimeEntryFailed(errorMessage));
  }
}

export function* fetchTimesheetSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/hierarchy/time_sheets/search/?${action.payload.query}`,
    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.fetchTimesheetSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.fetchTimesheetFailed(errorMessage));
  }
}

export function* reviewTimesheetSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/hierarchy/time_sheets/${action.payload.reviewBody.id}/review/?${action.payload.query}`,
    headers: {
      ...action.payload.header,
    },
  };
  try {
    const response = yield Client.request(config);
    yield put(
      TimesheetAction.reviewTimesheetSuccess({
        ...response,
        reviewBody: action.payload.reviewBody,
      })
    );
    notification.success({
      message: `Timesheet reviewed successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.reviewTimesheetFailed(errorMessage));
  }
}

export function* fetchAllUserTimesheetsSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/hierarchy/time_sheets/search/?${action.payload.query}`,
    headers: {
      ...action.payload.header,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(TimesheetAction.fetchAllUserTimesheetsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimesheetAction.fetchAllUserTimesheetsFailed(errorMessage));
  }
}
