import { put } from "redux-saga/effects";
import { GroupInsightReportAction } from "../../action";
import { Client } from "../../../../utils/transport";

export function* GetGroupInsightReportData(data) {
  
  let config = {
    method: "POST",
    url: "query/reports/group_inside_report?return_all=true",
    // url: `api/v1/identities/advanced/?skip_email=${data.data.skipEmail}`,
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    fetchPolicy: "no-cache",
    data: data.bodyData,
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        GroupInsightReportAction.GroupInsightReportSuccess(response.data.data)
      );
    } else {
      yield put(GroupInsightReportAction.GroupInsightReportFailed(response));
    }
  } catch (err) {
    yield put(GroupInsightReportAction.GroupInsightReportFailed(err));
  }
}
