import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchTimeEntryLoading: true,
  timeEntryData: {},
  timeEntryList: [],
  pagination: initialPagination,
  timeEntryLoading: false,
  timeEntrySuccess: false,
  submitTimeEntryLoading: false,
  deleteTimeEntrySuccess: false,
  deleteTimeEntryLoading: false,
  timeEntryErrorMessage: "",
  fetchTimesheetLoading: false,
  timesheetData: {},
  timesheetList: [],
  allUserTimesheetsList: [],
  fetchAllUserTimesheetsLoading: false,
  timesheetErrorMessage: "",
  timesheetReviewLoading: false,
  timesheetReviewSuccess: false,
  timesheetPagination: initialPagination,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_TIME_ENTRY:
      return {
        ...state,
        fetchTimeEntryLoading: true,
        timeEntryErrorMessage: "",
      };

    case actionTypes.FETCH_TIME_ENTRY_SUCCESS:
      return {
        ...state,
        fetchTimeEntryLoading: false,
        timeEntryData: (action.payload?.data || []).reduce(
          (acc, timeEntry) => ({ ...acc, [timeEntry.id]: timeEntry }),
          {}
        ),
        timeEntryList: action.payload.data,
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_TIME_ENTRY_FAILED:
      return {
        ...state,
        fetchTimeEntryLoading: false,
        timeEntryErrorMessage: action.payload,
      };

    case actionTypes.CREATE_TIME_ENTRY:
      return {
        ...state,
        timeEntryLoading: true,
        timeEntryErrorMessage: "",
        timeEntrySuccess: false,
      };

    case actionTypes.CREATE_TIME_ENTRY_SUCCESS: {
      return {
        ...state,
        timeEntryLoading: false,
        timeEntryData: {
          ...state.timeEntryData,
          [action.payload.id]: action.payload,
        },
        timeEntryList: [...state.timeEntryList, action.payload],
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        timeEntrySuccess: true,
      };
    }

    case actionTypes.CREATE_TIME_ENTRY_FAILED:
      return {
        ...state,
        timeEntryLoading: false,
        timeEntryErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_TIME_ENTRY:
      return {
        ...state,
        timeEntryLoading: true,
        timeEntryErrorMessage: "",
        timeEntrySuccess: false,
      };

    case actionTypes.UPDATE_TIME_ENTRY_SUCCESS:
      return {
        ...state,
        timeEntryLoading: false,
        timeEntryData: {
          ...state.timeEntryData,
          [action.payload.id]: action.payload,
        },
        timeEntrySuccess: true,
      };

    case actionTypes.UPDATE_TIME_ENTRY_FAILED:
      return {
        ...state,
        timeEntryLoading: false,
        timeEntryErrorMessage: action.payload,
      };

    case actionTypes.SET_TIME_ENTRY_ERROR_MESSAGE:
      return {
        ...state,
        timeEntryErrorMessage: action.payload,
      };

    case actionTypes.DELETE_TIME_ENTRY:
      return {
        ...state,
        deleteTimeEntryLoading: true,
        timeEntryErrorMessage: "",
      };

    case actionTypes.DELETE_TIME_ENTRY_SUCCESS: {
      const newTimesheetData = { ...state.timeEntryData };
      delete newTimesheetData[action.payload.id];
      return {
        ...state,
        timeEntryData: newTimesheetData,
        timeEntryList: [...state.timeEntryList].filter(
          (item) => item.id !== action.payload.id
        ),
        deleteTimeEntryLoading: false,
      };
    }

    case actionTypes.DELETE_TIME_ENTRY_FAILED:
      return {
        ...state,
        deleteTimeEntryLoading: false,
        timeEntryErrorMessage: action.payload,
      };

    case actionTypes.BULK_SUBMIT_TIME_ENTRY:
      return {
        ...state,
        submitTimeEntryLoading: true,
        timeEntryErrorMessage: "",
        timeEntrySuccess: false,
      };

    case actionTypes.BULK_SUBMIT_TIME_ENTRY_SUCCESS: {
      const newTimeEntryData = { ...state.timeEntryData };
      action.payload.forEach((timeEntryId) => {
        newTimeEntryData[timeEntryId].status = "PENDING";
      });
      return {
        ...state,
        submitTimeEntryLoading: false,
        timeEntrySuccess: true,
        timeEntryData: newTimeEntryData,
      };
    }

    case actionTypes.BULK_SUBMIT_TIME_ENTRY_FAILED:
      return {
        ...state,
        submitTimeEntryLoading: false,
        timeEntryErrorMessage: action.payload,
      };

    case actionTypes.FETCH_TIMESHEET:
      return {
        ...state,
        fetchTimesheetLoading: true,
        timesheetErrorMessage: "",
      };

    case actionTypes.FETCH_TIMESHEET_SUCCESS:
      return {
        ...state,
        fetchTimesheetLoading: false,
        timesheetData: (action.payload?.data || []).reduce(
          (acc, timesheet) => ({ ...acc, [timesheet.id]: timesheet }),
          { ...state.timesheetData }
        ),
        timesheetList: action.payload.data,
        timesheetPagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_TIMESHEET_FAILED:
      return {
        ...state,
        fetchTimesheetLoading: false,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.FETCH_ALL_USER_TIMESHEETS:
      return {
        ...state,
        fetchAllUserTimesheetsLoading: true,
        allUserTimesheetsListL: [],
        timesheetErrorMessage: "",
      };

    case actionTypes.FETCH_ALL_USER_TIMESHEETS_SUCCESS:
      return {
        ...state,
        fetchAllUserTimesheetsLoading: false,
        timesheetData: (action.payload?.data || []).reduce(
          (acc, timesheet) => ({ ...acc, [timesheet.id]: timesheet }),
          { ...state.timesheetData }
        ),
        allUserTimesheetsList: action.payload.data,
        timesheetPagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_ALL_USER_TIMESHEETS_FAILED:
      return {
        ...state,
        fetchAllUserTimesheetsLoading: false,
        timesheetErrorMessage: action.payload,
      };

    case actionTypes.REVIEW_TIMESHEET:
      return {
        ...state,
        timesheetReviewLoading: true,
        timesheetReviewSuccess: false,
        timesheetErrorMessage: "",
      };

    case actionTypes.REVIEW_TIMESHEET_SUCCESS: {
      return {
        ...state,
        timesheetReviewLoading: false,
        timesheetData: {
          ...state.timesheetData,
          [action.payload.reviewBody.id]: {
            ...action.payload.data,
            entries: state.timesheetData[
              action.payload.reviewBody.id
            ].entries.map((entry) => ({
              ...entry,
              status:
                action.payload.reviewBody.action === "REOPEN"
                  ? "DRAFT"
                  : action.payload.reviewBody.action === "APPROVE"
                  ? "APPROVED"
                  : "REJECTED",
            })),
          },
        },
        timesheetReviewSuccess: true,
      };
    }

    case actionTypes.REVIEW_TIMESHEET_FAILED:
      return {
        ...state,
        timesheetReviewLoading: false,
        timesheetReviewSuccess: false,
        timesheetErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
