import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchURLBlockingLoading: false,
  urlblockingData: [],
  fetchURLPoliciesAttachmentLoading: false,
  urlPoliciesAttachmentData: [],
  pagination: initialPagination,
  urlblockingLoading: false,
  createurlblockingSuccess: false,
  createurlblockingData: {},
  urlPoliciesAttachmentLoading: false,
  createurlPoliciesAttachmentSuccess: false,
  deleteURLBlockingSuccess: false,
  deleteURLBlockingLoading: false,
  deleteURLPolicyAttachmentSuccess: false,
  errorMessage: "",
  updateurlblockingSuccess: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_URLBlOCKING:
      return {
        ...state,
        fetchURLBlockingLoading: true,
        errorMessage: "",
      };

    case actionTypes.FETCH_URLBlOCKING_SUCCESS:
      return {
        ...state,
        fetchURLBlockingLoading: false,
        urlblockingData: action.payload.data,

        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_URLBlOCKING_FAILED:
      return {
        ...state,
        fetchURLBlockingLoading: false,
        urlblockingData: [],
        pagination: initialPagination,
        errorMessage: action.payload,
      };

    case actionTypes.FETCH_URL_POLICIES_ATTACHMENT:
      return {
        ...state,
        fetchURLPoliciesAttachmentLoading: true,
        errorMessage: "",
      };

    case actionTypes.FETCH_URL_POLICIES_ATTACHMENT_SUCCESS:
      return {
        ...state,
        fetchURLPoliciesAttachmentLoading: false,
        urlPoliciesAttachmentData: action.payload.data,
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_URL_POLICIES_ATTACHMENT_FAILED:
      return {
        ...state,
        fetchURLPoliciesAttachmentLoading: false,
        urlPoliciesAttachmentData: [],
        pagination: initialPagination,
        errorMessage: action.payload,
      };

    case actionTypes.CREATE_URLBlOCKING:
      return {
        ...state,
        urlblockingLoading: true,
        errorMessage: "",
        createurlblockingSuccess: false,
        createurlblockingData: {},
      };

    case actionTypes.CREATE_URLBlOCKING_SUCCESS:
      return {
        ...state,
        createurlblockingSuccess: true,
        urlblockingLoading: false,
        urlblockingData: [...state.urlblockingData, action.payload],
        createurlblockingData: action.payload,
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
      };

    case actionTypes.CREATE_URLBlOCKING_FAILED:
      return {
        ...state,
        urlblockingLoading: false,
        errorMessage: action.payload,
        createurlblockingData: {},
      };

    case actionTypes.CREATE_URL_POLICIES_ATTACHMENT:
      return {
        ...state,
        urlPoliciesAttachmentLoading: true,
        errorMessage: "",
        createurlPoliciesAttachmentSuccess: false,
      };

    case actionTypes.CREATE_URL_POLICIES_ATTACHMENT_SUCCESS:
      return {
        ...state,
        createurlPoliciesAttachmentSuccess: true,
        urlPoliciesAttachmentLoading: false,
        urlPoliciesAttachmentData: [
          ...state.urlPoliciesAttachmentData,
          action.payload,
        ],
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
      };

    case actionTypes.CREATE_URL_POLICIES_ATTACHMENT_FAILED:
      return {
        ...state,
        urlPoliciesAttachmentLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.UPDATE_URLBlOCKING:
      return {
        ...state,
        urlblockingLoading: true,
        errorMessage: "",
        updateurlblockingSuccess: false,
      };

    case actionTypes.UPDATE_URLBlOCKING_SUCCESS:
      const fetchURLPolicyList = [...state.urlblockingData];
      const findIndexURLPolicy = fetchURLPolicyList.findIndex(
        (item) => item.id == action.data.id
      );
      fetchURLPolicyList[findIndexURLPolicy] = action.data;
      return {
        ...state,
        urlblockingLoading: false,
        urlblockingData: findIndexURLPolicy,
        updateurlblockingSuccess: true,
      };

    case actionTypes.UPDATE_URLBlOCKING_FAILED:
      return {
        ...state,
        urlblockingLoading: false,
        errorMessage: action.payload,
      };
    case actionTypes.UPDATE_URL_POLICY_ATTACHMENT:
      return {
        ...state,
        urlPoliciesAttachmentLoading: true,
        errorMessage: "",
        updateurlblockingSuccess: false,
      };

    case actionTypes.UPDATE_URL_POLICY_ATTACHMENT_SUCCESS:
      const fetchList = [...state.urlPoliciesAttachmentData];
      const findIndex = fetchList.findIndex(
        (item) => item.id == action.data.id
      );
      fetchList[findIndex] = action.data;
      return {
        ...state,
        urlPoliciesAttachmentLoading: false,
        urlPoliciesAttachmentData: fetchList,
        updateurlblockingSuccess: true,
      };

    case actionTypes.UPDATE_URL_POLICY_ATTACHMENT_FAILED:
      return {
        ...state,
        urlPoliciesAttachmentLoading: false,
        errorMessage: action.payload,
      };
    case actionTypes.DELETE_URLBlOCKING:
      return {
        ...state,
        deleteURLBlockingLoading: true,
        errorMessage: "",
        deleteURLBlockingSuccess: false,
      };

    case actionTypes.DELETE_URLBlOCKING_SUCCESS:
      return {
        ...state,
        deleteURLBlockingLoading: false,
        deleteURLBlockingSuccess: true,
      };

    case actionTypes.DELETE_URLBlOCKING_FAILED:
      return {
        ...state,
        deleteURLBlockingLoading: false,
        errorMessage: action.payload,
      };
    case actionTypes.DELETE_URL_POLICY_ATTACHMENT:
      return {
        ...state,
        deleteURLBlockingLoading: true,
        errorMessage: "",
        deleteURLPolicyAttachmentSuccess: false,
      };

    case actionTypes.DELETE_URL_POLICY_ATTACHMENT_SUCCESS:
      return {
        ...state,
        deleteURLBlockingLoading: false,
        deleteURLPolicyAttachmentSuccess: true,
      };

    case actionTypes.DELETE_URL_POLICY_ATTACHMENT_FAILED:
      return {
        ...state,
        deleteURLBlockingLoading: false,
        errorMessage: action.payload,
        
      };

    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
