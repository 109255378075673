import * as actionTypes from "./actionTypes";

//*  URLBlockings *//

export const fetchURLBlocking = (payload) => {
  return {
    type: actionTypes.FETCH_URLBlOCKING,
    payload,
  };
};

export const fetchURLBlockingSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_URLBlOCKING_SUCCESS,
    payload,
  };
};

export const fetchURLBlockingFailed = (payload) => {
  return {
    type: actionTypes.FETCH_URLBlOCKING_FAILED,
    payload,
  };
};

export const fetchURLPoliciesAttachment = (payload) => {
  return {
    type: actionTypes.FETCH_URL_POLICIES_ATTACHMENT,
    payload,
  };
};

export const fetchURLPoliciesAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_URL_POLICIES_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const fetchURLPoliciesAttachmentFailed = (payload) => {
  return {
    type: actionTypes.FETCH_URL_POLICIES_ATTACHMENT_FAILED,
    payload,
  };
};

export const createURLBlocking = (payload) => {
  return {
    type: actionTypes.CREATE_URLBlOCKING,
    payload,
  };
};

export const createURLBlockingSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_URLBlOCKING_SUCCESS,
    payload,
  };
};

export const createURLBlockingFailed = (payload) => {
  return {
    type: actionTypes.CREATE_URLBlOCKING_FAILED,
    payload,
  };
};

export const createURLPolicyAttachment = (payload) => {
  return {
    type: actionTypes.CREATE_URL_POLICIES_ATTACHMENT,
    payload,
  };
};

export const createURLPolicyAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_URL_POLICIES_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const createURLPolicyAttachmentFailed = (payload) => {
  return {
    type: actionTypes.CREATE_URL_POLICIES_ATTACHMENT_FAILED,
    payload,
  };
};

export const deleteURLBlocking = (payload) => {
  return {
    type: actionTypes.DELETE_URLBlOCKING,
    payload,
  };
};

export const deleteURLBlockingSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_URLBlOCKING_SUCCESS,
    payload,
  };
};

export const deleteURLBlockingFailed = (payload) => {
  return {
    type: actionTypes.DELETE_URLBlOCKING_FAILED,
    payload,
  };
};

export const deleteURLPolicyAttachment = (payload) => {
  return {
    type: actionTypes.DELETE_URL_POLICY_ATTACHMENT,
    payload,
  };
};

export const deleteURLPolicyAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_URL_POLICY_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const deleteURLPolicyAttachmentFailed = (payload) => {
  return {
    type: actionTypes.DELETE_URL_POLICY_ATTACHMENT_FAILED,
    payload,
  };
};

export const updateURLBlocking = (payload) => {
  return {
    type: actionTypes.UPDATE_URLBlOCKING,
    payload,
  };
};

export const updateURLBlockingSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_URLBlOCKING_SUCCESS,
    payload,
  };
};

export const updateURLBlockingFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_URLBlOCKING_FAILED,
    payload,
  };
};

export const updateURLPolicyAttachment = (payload) => {
  return {
    type: actionTypes.UPDATE_URL_POLICY_ATTACHMENT,
    payload,
  };
};

export const updateURLPolicyAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_URL_POLICY_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const updateURLPolicyAttachmentFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_URL_POLICY_ATTACHMENT_FAILED,
    payload,
  };
};

export const setErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    payload,
  };
};
