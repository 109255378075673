import React from "react";

export const Timesheet = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
    >
      <path
        d="M11.6,10h1.2v2.3l2,1.1l-0.6,1L11.6,13V10z M3.6,2h11.2c0.4,0,0.8,0.2,1.1,0.5c0.3,0.3,0.5,0.7,0.5,1.1v4.9
	c1,1,1.6,2.4,1.6,3.9c0,1.5-0.6,2.9-1.6,4s-2.5,1.6-4,1.6c-1.5,0-2.9-0.6-3.9-1.6H3.6c-0.4,0-0.8-0.2-1.1-0.5
	C2.2,15.6,2,15.2,2,14.8V3.6c0-0.4,0.2-0.8,0.5-1.1S3.2,2,3.6,2z M3.6,12.4v2.4h3.7c-0.3-0.7-0.5-1.5-0.5-2.4H3.6z M3.6,6.8h4.8V4.4
	H3.6V6.8z M14.8,6.8V4.4H10v2.4H14.8z M3.6,10.8H7c0.3-0.9,0.8-1.7,1.4-2.4H3.6V10.8z M12.4,8.5c-1,0-2,0.4-2.7,1.1
	c-0.7,0.7-1.1,1.7-1.1,2.7c0,2.1,1.7,3.9,3.9,3.9c0.5,0,1-0.1,1.5-0.3c0.5-0.2,0.9-0.5,1.3-0.8s0.6-0.8,0.8-1.3
	c0.2-0.5,0.3-1,0.3-1.5C16.3,10.3,14.5,8.5,12.4,8.5z"
      />
    </svg>
  );
};
