import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import { Layout, Menu, Badge } from "antd";

import { SettingsM } from "./../../components/icons/setting_m";
import { Attendance } from "./../../components/icons/attendance";
import { Dashboard } from "./../../components/icons/dashboard";
import { Wellness } from "./../../components/icons/wellness";
import { Livestream } from "./../../components/icons/livestream";
import { Activity } from "./../../components/icons/activity";
import { Productivity } from "./../../components/icons/productivity";
import { Timeline } from "./../../components/icons/timeline";
import { Application } from "./../../components/icons/application";
import { Reports } from "./../../components/icons/reports";
import { Project } from "./../../components/icons/project";
import logolight from "./../../assets/images/logo-light.png";
import { Alerts } from "components/icons/alerts";
import { TbLiveView } from "react-icons/tb";
import { MdScreenshotMonitor } from "react-icons/md";
import { Realtime } from "components/icons/RealTime";
import { Analytics } from "components/icons/Analytics";
import { Leave } from "components/icons/leave";

import "./index.scss";
import {
  ACTIVITY,
  ALERTS,
  APPLICATION,
  ATTENDANCE,
  DASHBOARD,
  LIVESTREAM,
  ME,
  PRODUCTIVITY,
  REPORTS,
  SETTINGS,
  TIMELINE,
  WELLNESS,
  PROJECTS,
  FIELD,
  SCREENSHOT,
  NOTEBOOK,
  LEAVE_MANAGEMENT,
  DEVICE360,
  GOALS,
  MANUALTIME,
  TIMESHEET,
} from "route/constant";
import { permissionsMap } from "constants/constant";
import { AuthContext } from "contexts/authContext";
import { AiOutlineUser } from "react-icons/ai";
import { GiNotebook } from "react-icons/gi";
import { getLogoUrl } from "utils/transport";
import { Deviceinfo } from "components/icons/deviceinfo";
import { Goals } from "components/icons/Goals";
import { ManualTime } from "../../components/icons/manualTime";
import IconToggle from "./IconToggle";
import { LuArrowLeftToLine, LuArrowRightFromLine } from "react-icons/lu";
import { Timesheet } from "../../components/icons/timesheet";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const Sidebar = (props) => {
  const logoRef = useRef(null);
  const { silderBtn } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState(
    localStorage.getItem("activeMenu") || "productivity"
  );
  const [activeTooltip, setActiveTooltip] = useState("Productivity suite");
  const navigate = useNavigate();

  const { legacyMenu } = useSelector((state) => state.tenantSettings);

  const {
    checkRequiredPermissions,
    coreApiUrl,
    setCookies,
    checkRequiredAccess,
    // collapsed,setCollapsed
  } = useContext(AuthContext);

  const getFilteredMenuItems = (item) =>
    checkRequiredPermissions(item.requiredPermissions) &&
    (!item.path || checkRequiredAccess(item.path, true)) &&
    (!item.children || item.children.length > 0);

  const legacyMenuGroup = [
    // {
    //   path: "/some-path",
    //   label: "a menu item",
    //   icon: <Icon type="fire" />,
    // },
    {
      icon: <Dashboard />,
      label: "Dashboard",
      path: DASHBOARD,
      key: "dashboard",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      path: ATTENDANCE,
      icon: <Attendance />,
      label: "Attendance",
      key: "attendance",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      icon: <Realtime />,
      label: "Real Time",
      key: "realtime",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
      children: [
        {
          path: LIVESTREAM,
          icon: <Livestream viewBox="0 0 24 24" />,
          label: "Livestream",
          key: "livestream",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: FIELD,
          icon: <TbLiveView className="w-[18px] mr-3.5" />,
          key: "field",
          label: "Field",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
      ].filter(getFilteredMenuItems),
    },
    {
      icon: <Analytics />,
      label: "Analytics",
      key: "analytics",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
      children: [
        {
          path: TIMELINE,
          icon: <Timeline viewBox="0 0 24 24" />,
          label: "Timeline",
          key: "timeline",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: ACTIVITY,
          icon: <Activity viewBox="0 0 24 24" />,
          label: "Activity",
          key: "activity",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: PRODUCTIVITY,
          icon: <Productivity viewBox="0 0 24 24" />,
          label: "Productivity",
          key: "productivity",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: GOALS,
          icon: <Goals viewBox="0 0 24 24" />,
          label: "Goals",
          key: "goals",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: SCREENSHOT,
          icon: <MdScreenshotMonitor className="w-[18px] mr-3.5" />,
          label: "Screenshots",
          key: "screenshots",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: APPLICATION,
          icon: <Application viewBox="0 0 24 24" />,
          label: "Apps & URLs",
          key: "app&url",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: WELLNESS,
          icon: <Wellness viewBox="0 0 24 24" />,
          label: "Wellness",
          key: "wellness",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
      ].filter(getFilteredMenuItems),
    },
    {
      path: DEVICE360,
      icon: <Deviceinfo viewBox="0 0 24 24" />,
      key: "device360",
      label: "Devices",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      path: MANUALTIME,
      icon: <ManualTime />,
      label: "Manual Time",
      key: "leaves",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,

        permissionsMap.TENANT_USER,
        permissionsMap.IAM_MANAGE_ALL,
      ],
    },
    {
      path: ALERTS,
      icon: <Alerts />,
      label: "Alerts",
      key: "alerts",
      requiredPermissions: [permissionsMap.TENANT_OWNER],
    },

    {
      path: REPORTS,
      icon: <Reports />,
      label: "Reports",
      key: "reports",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      path: TIMESHEET,
      icon: <Timesheet />,
      label: (
        <div className="flex items-center space-x-2">
          <span>Timesheet</span>
          {/* <Badge
            count={"Trial"} // This is where the badge count goes, replace 'Trial' with your dynamic value
            style={{ backgroundColor: "gold", color: "#000" }} // Gold badge with black text
            className="text-sm"
          /> */}
          <Badge count="Trial" size="small" color="gold" />
        </div>
      ),
    },
    {
      path: PROJECTS,
      icon: <Project />,
      label: "Projects",
      key: "project",
      requiredPermissions: [permissionsMap.TENANT_USER],
    },

    {
      path: LEAVE_MANAGEMENT,
      icon: <Leave />,
      label: "Leaves",
      key: "leaves",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
        permissionsMap.TENANT_USER,
        permissionsMap.IAM_MANAGE_ALL,
      ],
    },
    {
      path: NOTEBOOK,
      icon: <GiNotebook size={20} />,
      label: "Notebook",
      key: "notebook",
      requiredPermissions: [permissionsMap.TENANT_USER],
    },
    {
      path: ME,
      icon: <AiOutlineUser size={20} />,
      label: !checkRequiredPermissions([
        permissionsMap.TENANT_OWNER,
        permissionsMap.TENANT_MANAGER,
      ])
        ? "Me"
        : "User Detail",
      key: "me",
      requiredPermissions: [permissionsMap.TENANT_USER],
    },
    {
      path: SETTINGS,
      icon: <SettingsM />,
      label: "Settings",
      key: "settings",
      requiredPermissions: [
        permissionsMap.TENANT_OWNER,
        permissionsMap.IAM_MANAGE_ALL,
        permissionsMap.IAM_TEAM_MANAGE,
      ],
    },
  ].filter(getFilteredMenuItems);

  const menuGroups = {
    productivity: [
      {
        icon: <Dashboard />,
        label: "Dashboard",
        path: DASHBOARD,
        key: "dashboard",
        requiredPermissions: [
          permissionsMap.TENANT_MANAGER,
          permissionsMap.TENANT_OWNER,
        ],
      },
      {
        icon: <Realtime />,
        label: "Real Time",
        key: "realtime",
        requiredPermissions: [
          permissionsMap.TENANT_MANAGER,
          permissionsMap.TENANT_OWNER,
        ],
        children: [
          {
            path: LIVESTREAM,
            icon: <Livestream viewBox="0 0 24 24" />,
            label: "Livestream",
            key: "livestream",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
          {
            path: FIELD,
            icon: <TbLiveView className="w-[18px] mr-3.5" />,
            key: "field",
            label: "Field",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
        ].filter(getFilteredMenuItems),
      },
      {
        icon: <Analytics />,
        label: "Analytics",
        key: "analytics",
        requiredPermissions: [
          permissionsMap.TENANT_MANAGER,
          permissionsMap.TENANT_OWNER,
        ],
        children: [
          {
            path: TIMELINE,
            icon: <Timeline viewBox="0 0 24 24" />,
            label: "Timeline",
            key: "timeline",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
          {
            path: ACTIVITY,
            icon: <Activity viewBox="0 0 24 24" />,
            label: "Activity",
            key: "activity",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
          {
            path: PRODUCTIVITY,
            icon: <Productivity viewBox="0 0 24 24" />,
            label: "Productivity",
            key: "productivity",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
          {
            path: GOALS,
            icon: <Goals viewBox="0 0 24 24" />,
            label: "Goals",
            key: "goals",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
          {
            path: SCREENSHOT,
            icon: <MdScreenshotMonitor className="w-[18px] mr-3.5" />,
            label: "Screenshots",
            key: "screenshots",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
          {
            path: APPLICATION,
            icon: <Application viewBox="0 0 24 24" />,
            label: "Apps & URLs",
            key: "app&url",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
          {
            path: WELLNESS,
            icon: <Wellness viewBox="0 0 24 24" />,
            label: "Wellness",
            key: "wellness",
            requiredPermissions: [
              permissionsMap.TENANT_MANAGER,
              permissionsMap.TENANT_OWNER,
            ],
          },
        ].filter(getFilteredMenuItems),
      },
      {
        path: MANUALTIME,
        icon: <ManualTime />,
        label: "Manual Time",
        key: "leaves",
        requiredPermissions: [
          permissionsMap.TENANT_MANAGER,
          permissionsMap.TENANT_OWNER,
          permissionsMap.TENANT_USER,
          permissionsMap.IAM_MANAGE_ALL,
        ],
      },
      {
        path: DEVICE360,
        icon: <Deviceinfo viewBox="0 0 24 24" />,
        key: "device360",
        label: "Devices",
        requiredPermissions: [
          permissionsMap.TENANT_MANAGER,
          permissionsMap.TENANT_OWNER,
        ],
      },
      {
        path: ALERTS,
        icon: <Alerts />,
        label: "Alerts",
        key: "alerts",
        requiredPermissions: [permissionsMap.TENANT_OWNER],
      },
    ].filter(getFilteredMenuItems),
    project: [
      {
        path: PROJECTS,
        icon: <Project />,
        label: "Projects",
        key: "project",
        requiredPermissions: [permissionsMap.TENANT_USER],
      },
      {
        path: TIMESHEET,
        icon: <Timesheet />,
        label: (
          <div className="flex items-center space-x-2">
            <span>Timesheet</span>
            {/* <Badge
              count={"Trial"} // This is where the badge count goes, replace 'Trial' with your dynamic value
              style={{ backgroundColor: "gold", color: "#000" }} // Gold badge with black text
              className="text-sm"
            /> */}
            <Badge count="Trial" size="small" color="gold" />
          </div>
        ),
      },

      {
        path: NOTEBOOK,
        icon: <GiNotebook size={20} />,
        label: "Notebook",
        key: "notebook",
        requiredPermissions: [permissionsMap.TENANT_USER],
      },
    ].filter(getFilteredMenuItems),
    hr: [
      {
        path: ATTENDANCE,
        icon: <Attendance />,
        label: "Attendance",
        key: "attendance",
        requiredPermissions: [
          permissionsMap.TENANT_MANAGER,
          permissionsMap.TENANT_OWNER,
        ],
      },
      {
        path: LEAVE_MANAGEMENT,
        icon: <Leave />,
        label: "Leaves",
        key: "leaves",
        requiredPermissions: [
          permissionsMap.TENANT_MANAGER,
          permissionsMap.TENANT_OWNER,
          permissionsMap.TENANT_USER,
          permissionsMap.IAM_MANAGE_ALL,
        ],
      },
    ].filter(getFilteredMenuItems),
  };

  const clickSilder = (val) => {
    setCollapsed(val);
    silderBtn(val);
  };

  const onLogoError = () => {
    setCookies();
    if (logoRef.current) {
      logoRef.current.src = logolight;
    }
  };

  const renderMenuItems = (menuItems) =>
    menuItems.map(({ label, icon, path, children, key }) =>
      children ? (
        <Menu.SubMenu
          key={key}
          title={label}
          icon={icon}
          popupClassName="submenu-item"
        >
          {renderMenuItems(children)}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={path} icon={icon}>
          <NavLink to={path}>{label}</NavLink>
        </Menu.Item>
      )
    );

  const toggleMenu = (menu) => {
    setActiveMenu(menu);
    setActiveTooltip(
      menu === "hr"
        ? "HR suite"
        : `${menu[0].toUpperCase()}${menu.substring(1)} suite`
    );
    localStorage.setItem("activeMenu", menu);
    const defaultRoute = menuGroups[menu]?.[0]?.path;
    if (defaultRoute) {
      navigate(defaultRoute);
    }
  };

  useEffect(() => {
    if (!legacyMenu) {
      const currentPath = location.pathname;

      for (const group in menuGroups) {
        if (
          menuGroups[group].some((item) =>
            item.path
              ? item.path === currentPath
              : item.children.some(
                  (childItem) => childItem.path === currentPath
                )
          )
        ) {
          let storedActiveMenu = localStorage.getItem("activeMenu");
          if (currentPath === "/dashboard") {
            const activeMenuPath = menuGroups[storedActiveMenu]?.[0]?.path;
            if (activeMenuPath && activeMenuPath !== currentPath) {
              navigate(activeMenuPath); // Always navigate to the first item of the active menu
            }
            return;
          }
          if (storedActiveMenu !== group) {
            storedActiveMenu = group;
          }

          setActiveMenu(storedActiveMenu);

          setActiveTooltip(
            storedActiveMenu === "hr"
              ? "HR suite"
              : `${storedActiveMenu[0].toUpperCase()}${storedActiveMenu.substring(
                  1
                )} suite`
          );
          localStorage.setItem("activeMenu", storedActiveMenu);

          break;
        }
      }
    }
  }, [location.pathname, legacyMenu]);

  const commonMenuItems = [
    {
      path: REPORTS,
      icon: <Reports />,
      label: "Reports",
      key: "reports",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      path: ME,
      icon: <AiOutlineUser size={20} />,
      label: !checkRequiredPermissions([
        permissionsMap.TENANT_OWNER,
        permissionsMap.TENANT_MANAGER,
      ])
        ? "Me"
        : "User Detail",
      key: "me",
      requiredPermissions: [permissionsMap.TENANT_USER],
    },
    {
      path: SETTINGS,
      icon: <SettingsM />,
      label: "Settings",
      key: "settings",
      requiredPermissions: [
        permissionsMap.TENANT_OWNER,
        permissionsMap.IAM_MANAGE_ALL,
      ],
    },
  ].filter(getFilteredMenuItems);

  const mergedMenu = [
    ...menuGroups.productivity,
    ...menuGroups.project,
    ...menuGroups.hr,
    ...commonMenuItems.filter((item) =>
      item.requiredPermissions.some((permission) =>
        [permissionsMap.TENANT_USER, permissionsMap.IAM_MANAGE_ALL].includes(
          permission
        )
      )
    ),
  ];

  return (
    <Sider
      trigger={null}
      collapsed={collapsed}
      className="aside_slid"
      width="220px"
      collapsible
    >
      <div
        className="sider-logo mainlogo"
        style={{ "background-color": "#0e082e", cursor: "pointer" }}
      >
        <img
          className="max-h-12 w-100%"
          ref={logoRef}
          src={
            checkRequiredAccess("whiteLabelling")
              ? `${getLogoUrl(coreApiUrl)}`
              : logolight
          }
          onError={onLogoError}
        />

        {collapsed ? (
          // <RiMenuUnfoldFill
          //   style={{
          //     color: "white",
          //     float: "right",
          //     paddingLeft: "5px",
          //     fontSize: "25px",
          //   }}
          //   onClick={() => clickSilder(!collapsed)}
          // />

          <LuArrowRightFromLine
            style={{
              color: "white",
              float: "right",
              paddingLeft: "5px",
              fontSize: "20px",
              color: "#5A5480",
            }}
            onClick={() => clickSilder(!collapsed)}
          />
        ) : (
          <LuArrowLeftToLine
            style={{
              color: "white",
              float: "right",
              margin: "0 -20px 0 0",
              fontSize: "20px",
              color: "#5A5480",
            }}
            onClick={() => clickSilder(!collapsed)}
          />
        )}
      </div>

      <div className="side_content">
        {checkRequiredPermissions([
          permissionsMap.TENANT_OWNER,
          permissionsMap.TENANT_MANAGER,
        ]) &&
          !legacyMenu &&
          !collapsed && (
            <p className="text-[#7667C0] pt-2 pb-4 pl-2 pr-2 text-xs">
              {activeTooltip}
            </p>
          )}

        <Menu
          mode="inline"
          selectable={true}
          className="menu-item"
          selectedKeys={[]}
        >
          {legacyMenu
            ? renderMenuItems(legacyMenuGroup)
            : checkRequiredPermissions([
                permissionsMap.TENANT_OWNER,
                permissionsMap.TENANT_MANAGER,
              ])
            ? renderMenuItems([...menuGroups[activeMenu], ...commonMenuItems])
            : renderMenuItems(mergedMenu)}
        </Menu>

        {checkRequiredPermissions([
          permissionsMap.TENANT_OWNER,
          permissionsMap.TENANT_MANAGER,
        ]) &&
          !legacyMenu && (
            <IconToggle
              activeMenu={activeMenu}
              toggleMenu={toggleMenu}
              collapsed={collapsed}
              menuGroups={menuGroups}
            />
          )}
      </div>
    </Sider>
  );
};

Sidebar.propTypes = {
  silderBtn: PropTypes.func,
};

export default Sidebar;
