import React from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import Productivitysuite from "../../assets/images/productivity suite.svg";
import Projectsuite from "../../assets/images/project suite.svg";
import HRsuite from "../../assets/images/HR suite.svg";

const IconToggle = ({
  activeMenu,
  toggleMenu,
  collapsed,
}: {
  activeMenu: string;
  toggleMenu: (id: string) => void;
  collapsed: boolean;
}) => {
  const icons = [
    {
      id: "productivity",
      icon: (
        <img
          src={Productivitysuite}
          alt="Productivity Suite"
          width={20}
          className={
            collapsed && activeMenu !== "productivity" ? "icon-black" : ""
          }
        />
      ),
      tooltip: "Productivity Suite",
    },
    {
      id: "project",
      icon: (
        <img
          src={Projectsuite}
          alt="Project Suite"
          width={20}
          className={collapsed && activeMenu !== "project" ? "icon-black" : ""}
        />
      ),
      tooltip: "Project Suite",
    },
    {
      id: "hr",
      icon: (
        <img
          src={HRsuite}
          alt="HR Suite"
          width={20}
          className={collapsed && activeMenu !== "hr" ? "icon-black" : ""}
        />
      ),
      tooltip: "HR Suite",
    },
  ];

  const menu = (
    <Menu>
      {icons
        .filter(({ id }) => id !== activeMenu)
        .map(({ id, icon, tooltip }) => (
          <Menu.Item key={id} onClick={() => toggleMenu(id)}>
            <div className="flex items-center gap-2">
              {icon}
              <span>{tooltip}</span>
            </div>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div
      className={`fixed bottom-0 p-4 border-t-[1px] border-solid border-[#35304f] bg-[#0e082e] left-0 overflow-hidden transition-all duration-300 ${
        collapsed ? "w-[80px]" : "w-[220px]"
      }`}
    >
      <div className="flex justify-around items-center bg-[#1a1142] rounded">
        {collapsed ? (
          <>
            {icons
              .filter(({ id }) => id === activeMenu)
              .map(({ id, icon, tooltip }) => (
                <Dropdown
                  key={id}
                  overlay={menu}
                  trigger={["hover", "click"]}
                  placement="topRight"
                >
                  <button
                    className="flex items-center justify-center w-14 border-0 cursor-pointer rounded bg-[#7b61ff] rounded-lg"
                    onClick={() => toggleMenu(id)}
                    aria-label={id}
                  >
                    <div className="py-2 text-white flex items-center">
                      {icon}
                    </div>
                  </button>
                </Dropdown>
              ))}
          </>
        ) : (
          icons.map(({ id, icon, tooltip }) => (
            <div key={id}>
              {collapsed ? (
                // No tooltip when collapsed
                <button
                  className={`flex items-center justify-center w-14 border-0 cursor-pointer rounded ${
                    activeMenu === id
                      ? "bg-[#7b61ff] rounded-lg"
                      : "bg-[#1a1142]"
                  }`}
                  onClick={() => toggleMenu(id)}
                  aria-label={id}
                >
                  <div
                    className={`py-2 ${
                      activeMenu === id ? "text-white" : "text-gray-300"
                    } flex items-center`}
                  >
                    {icon}
                  </div>
                </button>
              ) : (
                // Show tooltip when not collapsed
                <Tooltip title={tooltip}>
                  <button
                    className={`flex items-center justify-center w-14 border-0 cursor-pointer rounded ${
                      activeMenu === id
                        ? "bg-[#7b61ff] rounded-lg"
                        : "bg-[#1a1142]"
                    }`}
                    onClick={() => toggleMenu(id)}
                    aria-label={id}
                  >
                    <div
                      className={`py-2 ${
                        activeMenu === id ? "text-white" : "text-gray-300"
                      } flex items-center`}
                    >
                      {icon}
                    </div>
                  </button>
                </Tooltip>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default IconToggle;
