import { put } from "redux-saga/effects";
import { URLBlockingAction } from "../action";

import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchURLBlockingSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/domain_blocking_policies/?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(URLBlockingAction.fetchURLBlockingSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.fetchURLBlockingFailed(errorMessage));
  }
}
export function* fetchURLPoliciesAttachmentSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/domain_policy_attachments/?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(
      URLBlockingAction.fetchURLPoliciesAttachmentSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.fetchURLPoliciesAttachmentFailed(errorMessage));
  }
}

export function* createURLBlockingSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/domain_blocking_policies/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(URLBlockingAction.createURLBlockingSuccess(response.data));
    notification.success({
      message: `Domain Blocking Created Successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.createURLBlockingFailed(errorMessage));
  }
}

export function* createURLPolicyAttachmentSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/domain_policy_attachments/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(
      URLBlockingAction.createURLPolicyAttachmentSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.createURLPolicyAttachmentFailed(errorMessage));
  }
}

export function* updateURLBlockingSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/domain_blocking_policies/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(URLBlockingAction.updateURLBlockingSuccess(response.data));
    notification.success({
      message: `Domain Blocking Updated Successfully !`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.updateURLBlockingFailed(errorMessage));
  }
}
export function* updateURLPloicyAttachmentSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/domain_policy_attachments/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(
      URLBlockingAction.updateURLPolicyAttachmentSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.updateURLPolicyAttachmentFailed(errorMessage));
  }
}
export function* deleteURLBlockingSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/domain_blocking_policies/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(URLBlockingAction.deleteURLBlockingSuccess());
    notification.success({
      message: `Domain Blocking Deleted Successfully !`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.deleteURLBlockingFailed(errorMessage));
  }
}

export function* deleteURLPolicyAttachmentSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/domain_policy_attachments/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(URLBlockingAction.deleteURLPolicyAttachmentSuccess());
   
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(URLBlockingAction.deleteURLPolicyAttachmentFailed(errorMessage));
  }
}
